var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-form", { ref: "editForm" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "LBLBASEINFO" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.editable,
                                expression: "editable",
                              },
                            ],
                            attrs: {
                              url: _vm.saveUrl,
                              isSubmit: _vm.isSave,
                              param: _vm.receivingSetHistory,
                              mappingType: _vm.mappingType,
                              label: "LBLSAVE",
                              icon: "save",
                            },
                            on: {
                              beforeAction: _vm.saveInfo,
                              btnCallback: _vm.saveCallback,
                            },
                          }),
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.editable && _vm.isOld,
                                expression: "editable && isOld",
                              },
                            ],
                            attrs: { label: "삭제", icon: "delete_forever" },
                            on: { btnClicked: _vm.deleteInfo },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-6 col-lg-6" },
                      [
                        _c("c-plant", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            disabled: _vm.isOld,
                            type: "edit",
                            name: "plantCd",
                          },
                          model: {
                            value: _vm.receivingSetHistory.plantCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.receivingSetHistory, "plantCd", $$v)
                            },
                            expression: "receivingSetHistory.plantCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-6 col-lg-6" },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            type: "datetime",
                            default: "today",
                            label: "보고일시",
                            name: "plantCd",
                          },
                          model: {
                            value: _vm.receivingSetHistory.reportDate,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.receivingSetHistory,
                                "reportDate",
                                $$v
                              )
                            },
                            expression: "receivingSetHistory.reportDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12",
                      },
                      [
                        _c(
                          "c-card",
                          {
                            staticClass: "cardClassDetailForm",
                            attrs: {
                              title: "설비 정보",
                              topClass: "topcolor-blue",
                            },
                          },
                          [
                            _c("template", { slot: "card-detail" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-xs-12 col-sm-12 col-md-6 col-lg-6",
                                },
                                [
                                  _c("c-fire-fighting", {
                                    attrs: {
                                      required: "",
                                      label: "관련 소방설비",
                                      name: "sopFireFightingId",
                                    },
                                    on: { dataChange: _vm.fireFightingChange },
                                    model: {
                                      value:
                                        _vm.receivingSetHistory
                                          .sopFireFightingId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.receivingSetHistory,
                                          "sopFireFightingId",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "receivingSetHistory.sopFireFightingId",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-xs-12 col-sm-12 col-md-6 col-lg-6",
                                },
                                [
                                  _c("c-text", {
                                    attrs: {
                                      disabled: "",
                                      editable: _vm.editable,
                                      label: "소방설비 종류",
                                      name: "sopFireFightingTypeName",
                                    },
                                    model: {
                                      value:
                                        _vm.receivingSetHistory
                                          .sopFireFightingTypeName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.receivingSetHistory,
                                          "sopFireFightingTypeName",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "receivingSetHistory.sopFireFightingTypeName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-xs-6 col-sm-6 col-md-4 col-lg-4",
                                },
                                [
                                  _c("c-text", {
                                    attrs: {
                                      editable: _vm.editable,
                                      label: "중계기 종류",
                                      name: "repeaterType",
                                    },
                                    model: {
                                      value:
                                        _vm.receivingSetHistory.repeaterType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.receivingSetHistory,
                                          "repeaterType",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "receivingSetHistory.repeaterType",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-xs-6 col-sm-6 col-md-4 col-lg-4",
                                },
                                [
                                  _c("c-text", {
                                    attrs: {
                                      editable: _vm.editable,
                                      label: "중계기 번호",
                                      name: "repeater",
                                    },
                                    model: {
                                      value: _vm.receivingSetHistory.repeater,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.receivingSetHistory,
                                          "repeater",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "receivingSetHistory.repeater",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-xs-6 col-sm-6 col-md-4 col-lg-4",
                                },
                                [
                                  _c("c-text", {
                                    attrs: {
                                      editable: _vm.editable,
                                      label: "제조사",
                                      name: "repeaterMenufCompany",
                                    },
                                    model: {
                                      value:
                                        _vm.receivingSetHistory
                                          .repeaterMenufCompany,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.receivingSetHistory,
                                          "repeaterMenufCompany",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "receivingSetHistory.repeaterMenufCompany",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12",
                      },
                      [
                        _c(
                          "c-card",
                          {
                            staticClass: "cardClassDetailForm",
                            attrs: { title: "장소", topClass: "topcolor-blue" },
                          },
                          [
                            _c("template", { slot: "card-detail" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-xs-6 col-sm-6 col-md-2 col-lg-2",
                                },
                                [
                                  _c("c-text", {
                                    attrs: {
                                      editable: _vm.editable,
                                      label: "장소_동",
                                      name: "locationDong",
                                    },
                                    model: {
                                      value:
                                        _vm.receivingSetHistory.locationDong,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.receivingSetHistory,
                                          "locationDong",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "receivingSetHistory.locationDong",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-xs-6 col-sm-6 col-md-2 col-lg-2",
                                },
                                [
                                  _c("c-text", {
                                    attrs: {
                                      editable: _vm.editable,
                                      label: "장소_층",
                                      name: "locationFloor",
                                    },
                                    model: {
                                      value:
                                        _vm.receivingSetHistory.locationFloor,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.receivingSetHistory,
                                          "locationFloor",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "receivingSetHistory.locationFloor",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-xs-6 col-sm-6 col-md-8 col-lg-8",
                                },
                                [
                                  _c("c-text", {
                                    attrs: {
                                      editable: _vm.editable,
                                      label: "세부위치",
                                      name: "locationDetail",
                                    },
                                    model: {
                                      value:
                                        _vm.receivingSetHistory.locationDetail,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.receivingSetHistory,
                                          "locationDetail",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "receivingSetHistory.locationDetail",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12",
                      },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            rows: 2,
                            label: "발생내용",
                            name: "happened",
                          },
                          model: {
                            value: _vm.receivingSetHistory.happened,
                            callback: function ($$v) {
                              _vm.$set(_vm.receivingSetHistory, "happened", $$v)
                            },
                            expression: "receivingSetHistory.happened",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            rows: 2,
                            label: "미흡사항",
                            name: "insufficiencies",
                          },
                          model: {
                            value: _vm.receivingSetHistory.insufficiencies,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.receivingSetHistory,
                                "insufficiencies",
                                $$v
                              )
                            },
                            expression: "receivingSetHistory.insufficiencies",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            rows: 2,
                            label: "원인분석",
                            name: "causeAnalysis",
                          },
                          model: {
                            value: _vm.receivingSetHistory.causeAnalysis,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.receivingSetHistory,
                                "causeAnalysis",
                                $$v
                              )
                            },
                            expression: "receivingSetHistory.causeAnalysis",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12",
                      },
                      [
                        _c(
                          "c-card",
                          {
                            staticClass: "cardClassDetailForm",
                            attrs: {
                              title: "근무자 대피",
                              topClass: "topcolor-blue",
                            },
                          },
                          [
                            _c("template", { slot: "card-detail" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-xs-6 col-sm-6 col-md-6 col-lg-6",
                                },
                                [
                                  _c("c-checkbox", {
                                    attrs: {
                                      editable: _vm.editable,
                                      isFlag: true,
                                      label: "대피유무",
                                      name: "evacuationFlag",
                                    },
                                    model: {
                                      value:
                                        _vm.receivingSetHistory.evacuationFlag,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.receivingSetHistory,
                                          "evacuationFlag",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "receivingSetHistory.evacuationFlag",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-xs-6 col-sm-6 col-md-6 col-lg-6",
                                },
                                [
                                  _c("c-text", {
                                    attrs: {
                                      editable: _vm.editable,
                                      type: "number",
                                      label: "대피인원",
                                      name: "evacuationCount",
                                    },
                                    model: {
                                      value:
                                        _vm.receivingSetHistory.evacuationCount,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.receivingSetHistory,
                                          "evacuationCount",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "receivingSetHistory.evacuationCount",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-xs-12 col-sm-12 col-md-12 col-lg-12",
                                },
                                [
                                  _c("c-textarea", {
                                    attrs: {
                                      editable: _vm.editable,
                                      rows: 1,
                                      label: "대피내용 / 미대피 사유",
                                      name: "evacuationDetails",
                                    },
                                    model: {
                                      value:
                                        _vm.receivingSetHistory
                                          .evacuationDetails,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.receivingSetHistory,
                                          "evacuationDetails",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "receivingSetHistory.evacuationDetails",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            rows: 2,
                            label: "개선방안",
                            name: "improvement",
                          },
                          model: {
                            value: _vm.receivingSetHistory.improvement,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.receivingSetHistory,
                                "improvement",
                                $$v
                              )
                            },
                            expression: "receivingSetHistory.improvement",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            rows: 2,
                            label: "비고",
                            name: "remarks",
                          },
                          model: {
                            value: _vm.receivingSetHistory.remarks,
                            callback: function ($$v) {
                              _vm.$set(_vm.receivingSetHistory, "remarks", $$v)
                            },
                            expression: "receivingSetHistory.remarks",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "감지기 동작 위치" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c("c-pick-file", {
                        attrs: {
                          editable: _vm.editable,
                          outlined: "",
                          dense: true,
                        },
                        on: { getImageData: _vm.getImageData },
                        scopedSlots: _vm._u([
                          {
                            key: "prepend",
                            fn: function () {
                              return [
                                _c("q-icon", {
                                  attrs: { name: "attach_file" },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.file,
                          callback: function ($$v) {
                            _vm.file = $$v
                          },
                          expression: "file",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12",
                      },
                      [
                        _c("q-img", {
                          attrs: {
                            src: _vm.receivingSetHistory
                              .detectorOperatingPositionSrc,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" },
                  [
                    _c("c-upload", {
                      attrs: {
                        isPhoto: true,
                        attachInfo: _vm.attachInfo,
                        editable: _vm.editable,
                        label: "관련 사진",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }