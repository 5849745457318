<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <c-card title="LBLBASEINFO" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-show="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="receivingSetHistory"
                  :mappingType="mappingType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveInfo"
                  @btnCallback="saveCallback" />
                <c-btn
                  v-show="editable && isOld"
                  label="삭제"
                  icon="delete_forever"
                  @btnClicked="deleteInfo" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <c-plant
                  :required="true"
                  :editable="editable"
                  :disabled="isOld"
                  type="edit"
                  name="plantCd"
                  v-model="receivingSetHistory.plantCd" />
              </div>
              <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <c-datepicker
                  :required="true"
                  :editable="editable"
                  type="datetime"
                  default="today"
                  label="보고일시"
                  name="plantCd"
                  v-model="receivingSetHistory.reportDate" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <c-card title="설비 정보" class="cardClassDetailForm" topClass="topcolor-blue">
                  <template slot="card-detail">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <c-fire-fighting
                        required
                        label="관련 소방설비"
                        name="sopFireFightingId"
                        v-model="receivingSetHistory.sopFireFightingId"
                        @dataChange="fireFightingChange">
                      </c-fire-fighting>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <c-text
                        disabled
                        :editable="editable"
                        label="소방설비 종류"
                        name="sopFireFightingTypeName"
                        v-model="receivingSetHistory.sopFireFightingTypeName">
                      </c-text>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4">
                      <c-text
                        :editable="editable"
                        label="중계기 종류"
                        name="repeaterType"
                        v-model="receivingSetHistory.repeaterType">
                      </c-text>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4">
                      <c-text
                        :editable="editable"
                        label="중계기 번호"
                        name="repeater"
                        v-model="receivingSetHistory.repeater">
                      </c-text>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4">
                      <c-text
                        :editable="editable"
                        label="제조사"
                        name="repeaterMenufCompany"
                        v-model="receivingSetHistory.repeaterMenufCompany">
                      </c-text>
                    </div>
                  </template>
                </c-card>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <c-card title="장소" class="cardClassDetailForm" topClass="topcolor-blue">
                  <template slot="card-detail">
                    <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
                      <c-text
                        :editable="editable"
                        label="장소_동"
                        name="locationDong"
                        v-model="receivingSetHistory.locationDong">
                      </c-text>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
                      <c-text
                        :editable="editable"
                        label="장소_층"
                        name="locationFloor"
                        v-model="receivingSetHistory.locationFloor">
                      </c-text>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-8 col-lg-8">
                      <c-text
                        :editable="editable"
                        label="세부위치"
                        name="locationDetail"
                        v-model="receivingSetHistory.locationDetail">
                      </c-text>
                    </div>
                  </template>
                </c-card>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <c-textarea
                  :editable="editable"
                  :rows="2"
                  label="발생내용" 
                  name="happened" 
                  v-model="receivingSetHistory.happened" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-textarea
                  :editable="editable"
                  :rows="2"
                  label="미흡사항" 
                  name="insufficiencies" 
                  v-model="receivingSetHistory.insufficiencies" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-textarea
                  :editable="editable"
                  :rows="2"
                  label="원인분석" 
                  name="causeAnalysis" 
                  v-model="receivingSetHistory.causeAnalysis" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <c-card title="근무자 대피" class="cardClassDetailForm" topClass="topcolor-blue">
                  <template slot="card-detail">
                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                      <c-checkbox
                        :editable="editable"
                        :isFlag="true"
                        label="대피유무"
                        name="evacuationFlag"
                        v-model="receivingSetHistory.evacuationFlag"
                      />
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                      <c-text
                        :editable="editable"
                        type="number"
                        label="대피인원"
                        name="evacuationCount"
                        v-model="receivingSetHistory.evacuationCount">
                      </c-text>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <c-textarea
                        :editable="editable"
                        :rows="1"
                        label="대피내용 / 미대피 사유"
                        name="evacuationDetails"
                        v-model="receivingSetHistory.evacuationDetails">
                      </c-textarea>
                    </div>
                  </template>
                </c-card>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-textarea
                  :editable="editable"
                  :rows="2"
                  label="개선방안" 
                  name="improvement" 
                  v-model="receivingSetHistory.improvement" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-textarea
                  :editable="editable"
                  :rows="2"
                  label="비고" 
                  name="remarks" 
                  v-model="receivingSetHistory.remarks" />
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <c-card title="감지기 동작 위치" class="cardClassDetailForm">
            <template slot="card-button">
              <c-pick-file :editable="editable" outlined v-model="file" :dense="true" @getImageData="getImageData">
                <template v-slot:prepend>
                  <q-icon name="attach_file"></q-icon>
                </template>
              </c-pick-file>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <q-img :src="receivingSetHistory.detectorOperatingPositionSrc"></q-img>
              </div>
            </template>
          </c-card>
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <c-upload 
                :isPhoto="true"
                :attachInfo="attachInfo"
                :editable="editable"
                label="관련 사진">
              </c-upload>
            </div>
          </div>
        </div>
      </div>
    </q-form>
  </div>
</template>
<script>
import { uid } from 'quasar';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'fireFightingReceivingSetHistoryInfo',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopFireFightingReceivingSetHistoryId: '',
      }),
    },
    receivingSetHistory: {
      type: Object,
      default: () => ({
        sopFireFightingReceivingSetHistoryId: '',  // 화재수신기 작동 이력 보고 일련번호
        plantCd: null,  // 사업장코드
        reportDate: '',  // 보고일
        locationDong: '',  // 장소_동
        locationFloor: '',  // 장소_층
        locationDetail: '',  // 세부위치
        sopFireFightingId: '',  // 소방설비 일련번호
        fireFightingName: '',  // 소방설비명
        sopFireFightingTypeName: '',  // 소방설비 종류명
        repeaterType: '',  // 중계기 번호
        repeater: '',  // 중계기
        repeaterMenufCompany: '',  // 제조사
        happened: '',  // 발생내용
        insufficiencies: '',  // 미흡사항
        causeAnalysis: '',  // 원인분석
        evacuationFlag: 'N',  // 대피유무
        evacuationCount: '',  // 대피인원
        evacuationDetails: '',  // 대피내용, 미대피 사유
        improvement: '',  // 개선방안
        remarks: '',  // 비고
        detectorOperatingPositionSrc: '', // 감지기 동작 위치
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
      }),
    },
    isOld: {
      type: Boolean,
      default: false,
    },
    attachInfo: {
      type: Object,
      default: () => ({
        isSubmit: '',
        taskClassCd: 'FIRE_RECEIVING_SET',
        taskKey: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      file: null,
      editable: true,
      isSave: false,
      saveUrl: '',
      deleteUrl: '',
      mappingType: 'PUT',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
  },
  computed: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.fft.fireFightingReceivingSetHistory.update.url
      this.deleteUrl = transactionConfig.fft.fireFightingReceivingSetHistory.delete.url
      // code setting
      // list setting
    },
    fireFightingChange(sopFireFightingId, beforeSopFireFightingId, fireFighting) {
      if (fireFighting) {
        this.$set(this.receivingSetHistory, 'fireFightingName', fireFighting.fireFightingName)
        this.$set(this.receivingSetHistory, 'sopFireFightingTypeName', fireFighting.sopFireFightingTypeName)
      } else {
        this.$set(this.receivingSetHistory, 'fireFightingName', null)
        this.$set(this.receivingSetHistory, 'sopFireFightingTypeName', null)
      }
    },
    getImageData(data) {
      this.$set(this.receivingSetHistory, 'detectorOperatingPositionSrc', data)
    },
    saveInfo() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.fft.fireFightingReceivingSetHistory.update.url
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.fft.fireFightingReceivingSetHistory.insert.url
        this.mappingType = 'POST';
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.receivingSetHistory.regUserId = this.$store.getters.user.userId;
              this.receivingSetHistory.chgUserId = this.$store.getters.user.userId;

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (!this.popupParam.sopFireFightingReceivingSetHistoryId) {
        this.$set(this.attachInfo, 'taskKey', result.data)
        this.$set(this.attachInfo, 'isSubmit', uid())
      }
      this.$set(this.popupParam, 'sopFireFightingReceivingSetHistoryId', result.data)
      this.$emit('getDetail');
    },
    deleteInfo() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.sopFireFightingReceivingSetHistoryId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
  }
};
</script>